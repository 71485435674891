
import { defineComponent, onMounted } from "vue";

import WidgetBranchPerfomance from "@/components/widgets/WidgetBranchPerfomance.vue";
import WidgetUpcomingAppointments from "@/components/widgets/WidgetUpcomingAppointments.vue";
import WidgetUpcomingAssignments from "@/components/widgets/WidgetUpcomingAssignments.vue";
import WidgetServices from "@/components/widgets/WidgetServices.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "branch-head-dashboard",
  components: {
    WidgetBranchPerfomance,
    // WidgetUpcomingAppointments,
    WidgetUpcomingAssignments,
    WidgetServices,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Branch Head Dashboard");
    });
  },
});
