import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-8" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-xl-12" }
const _hoisted_7 = { class: "col-lg-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WidgetBranchPerfomance = _resolveComponent("WidgetBranchPerfomance")!
  const _component_WidgetServices = _resolveComponent("WidgetServices")!
  const _component_WidgetUpcomingAssignments = _resolveComponent("WidgetUpcomingAssignments")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_WidgetBranchPerfomance, {
            "widget-classes": "card-xxl-stretch mb-5 mb-xl-8",
            "chart-color": "primary",
            "chart-height": "300"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_WidgetServices, {
            "widget-classes": "card-xxl-stretch mb-5 mb-xl-8",
            "chart-color": "primary",
            "chart-height": "300"
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_WidgetUpcomingAssignments, {
        "widget-classes": "card-xxl-stretch mb-5 mb-xl-8",
        "chart-color": "primary",
        "chart-height": "300"
      })
    ])
  ]))
}